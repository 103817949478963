import React from 'react'
import Faq from 'react-faq-component';
import styled from 'styled-components';
import {ContentHeader} from './styles/global-styles';

const styles = {
  bgColor: 'black',
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: '#C0C0C0',
  arrowColor: "#C0C0C0",
  rowContentPaddingLeft: "1rem",
};

const AppFaq = ({dataToShow, fullPageHeight, title}) => {
  return (
    <div>
      <ContentHeader marginBottom="0">{title}</ContentHeader>
      <FaqWrapper fullHeight={fullPageHeight}>
        <Faq
          data={dataToShow}
          styles={styles}
        />
      </FaqWrapper>
    </div>
  )
}

export default AppFaq

const FaqWrapper = styled.div`
  padding: 2rem;
  text-align: start;
  min-height: ${({fullHeight}) => (fullHeight === "true" ? '100vh' : '0px')};
`