export const hardWayData = [
  [
    {title: "Crie um E-commerce", description: "Utilize um criador de e-commerce e tenha uma loja para vender seu conteúdo.", pros: ["Página e domínio próprios", "Liberdade de identidade visual"], cons: ["Nada privado: é só saber o domínio do seu site para ter acesso", "Difícil de criar opções novas sob demanda", "Custo mensal", "Custo de domínio", "Taxa por venda", "Página e estrutura pensadas para produtos físicos", "Mais complexo para seus fãs"], img: ""},
    {title: "Venda assinaturas em plataformas", description: "Crie conta em plataformas que permitem vender assinatura em troca de postagens.", pros: ["Ganhos recorrentes de fãs mais engajados", "Certa previsibilidade na renda mensal"], cons: ["Retenção normalmente baixa", "Seguidores assinam, consomem o conteúdo já disponível e cancelam", "Compromisso em criar conteúdos exclusivos toda semana", "Por ser assinatura, o valor não pode ser alto", "Diversas plataformas cobram em dolar", "Taxas de 20 a 45%", "Todos acessam o mesmo tipo de conteúdo"], img: ""}
  ],
  [
    {title: "Diretamente por chat", description: "Converse com quem tem interesse no seu conteúdo, cobre e entregue o conteúdo por conta própria.", pros: ["Total controle sobre todas as etapas do processo", "Possibilidade de não ter qualquer taxa no pagamento", "Fã pode ter acesso a packs personalizados e especiais"], cons: ["Você é responsável por cobrar o cliente", "Menos flexibilidade de pagamento para o cliente", "Problemas são totalmente tratados por você", "É mais lento para o seguidor conseguir acesso ao conteúdo"], img: ""},
    {title: "Use plataformas de infoprodutos", description: "Use uma das ferramentas de venda de infoprodutos, como cursos e e-books.", pros: ["Funcionalidades direcionadas a produtos digitais", "Diversas formas de pagamento", "Entrega do conteúdo e suporte facilitados pela plataforma"], cons: ["Restrições quanto ao tipo de conteúdo", "Foco em cursos online", "Mais burocrático e complexo de usar", "Pouca capacidade de personalizações para fãs especiais", "Todos acessam o mesmo tipo de conteúdo"], img: ""}
  ],
]

export const easyWaySteps = [
  {title: "1 - Crie os packs de conteúdo", description: "Importe seu conteúdo e comece a criar seus pacotes. É só escolher quais itens farão parte, definir um preço e pronto! Você pode até adicionar recados especiais nos packs.", img: ""},
  {title: "2 - Gere links e compartilhe", description: "Com seu pack montado, você pode gerar infinitos links para ele. Cada link é uma página única, e você pode definir um desconto. Aí é só enviar para seus seguidores, fãs ou clientes. Eles podem pagar com cartão de crédito ou boleto!", img: ""},
  {title: "3 - Receba seus ganhos todo mês", description: "Escolha a forma mais adequada para receber seus ganhos: PayPal ou PIX. Você receberá todo início de mês os ganhos. Não se preocupe: você tem um relatório mensal detalhando cada venda!", img: ""},
]

export const faqCreatorsData = {
  title: "",
  rows: [
    {
      title: "Como seguidores acessam o conteúdo?",
      content: `É simples: você começa compartilhando o link para o pack que está oferecendo. A pessoa então irá acessar uma página web com os dados do seu pack, na qual poderá clicar em "Liberar Acesso" e, após realizar o pagamento, irá ter acesso ao conteúdo pela própria plataforma (https://app.privetly.com).`,
    },
    {
      title: "Como eu recebo os ganhos das vendas?",
      content: `Há duas formas: PIX e PayPal. Você configura pelo app qual a melhor forma para você, e mensalmente você receberá uma transferência dos valores consolidados de vendas.`,
    },
    {
      title: "Quando eu recebo os ganhos das vendas?",
      content: `Todo início de mês, entre os dias 01 e 05, você recebe uma transferência das vendas concretizadas nos 30 dias anteriores. Você também receberá um relatório por email com a consolidação das vendas e rendimentos, explicando o porquê do valor recebido.`,
    },
    {
      title: "E se quem me segue tiver algum problema?",
      content: `Não se preocupe, se houver qualquer problema do nosso lado quanto a pagamento ou a entrega do conteúdo, daremos todo o suporte para a pessoa. Você não precisará se preocupar!`,
    },
    {
      title: "Como funcionam os estornos?",
      content: `Se quem comprou abrir uma disputa até 7 dias após o pagamento, alegando que quem criou o pack agiu de má fé, iremos nos comunicar com você. Se você não responder dentro dos 7 dias, o estorno será realizado, bem como se entendermos que de fato houve alguma fraude da sua parte. Neste caso, sua conta poderá ser bloqueada.`,
    },
    {
      title: "Quanto suporte é dado para quem cria os packs?",
      content: `Criadores e criadoras são essenciais para nós. Sendo assim, nos comprometemos em estarmos juntos com você em todas as etapas, resolvendo qualquer problema que possa surgir. Nós crescemos quando você cresce, então temos total motivação para ajudar ao máximo.`,
    },
  ],
};